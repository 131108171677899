import './contact.css';
import LinkedInIcon from '../../assets/linkedin.png';
import instagramIcon from '../../assets/instagram.png';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateForm = () => {
        const errors = {};
        const formElements = form.current.elements;
        if (!formElements.from_name.value) errors.name = 'Name is required';
        if (!formElements.from_email.value) errors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formElements.from_email.value)) errors.email = 'Email is invalid';
        if (!formElements.message.value) errors.message = 'Message is required';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        emailjs.sendForm('service_0iahwth', 'template_ux1q1or', form.current, 'an5S0q-JmrVzr3Y0k')
            .then((result) => {
                console.log(result.text);
                setIsSubmitted(true);
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div id='contactPage' className="contactPage">
            <div id="clients" className="clients">
                <h1 className="contactPageTitle">Connect With Me!</h1>
                <div className="links">
                    
                    <a href="https://www.linkedin.com/in/frontendevelopers/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                        <img src={LinkedInIcon} alt="LinkedIn" className="link" />
                    </a>
                    <a href="https://www.instagram.com/_arshad_02_20/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Instagram" className="link" />
                    </a>
                </div>
            </div>
            <div id="contact" className="contact">
                <form className="contactForm" ref={form} onSubmit={sendEmail}>
                    <label htmlFor="name" className="sr-only">Your Name</label>
                    <input 
                        id="name" 
                        type="text" 
                        className={`name ${formErrors.name ? 'error' : ''}`} 
                        placeholder='Your Name' 
                        name='from_name' 
                    />
                    {formErrors.name && <p className="errorMsg">{formErrors.name}</p>}
                    
                    <label htmlFor="email" className="sr-only">Your Email</label>
                    <input 
                        id="email" 
                        type="email" 
                        className={`email ${formErrors.email ? 'error' : ''}`} 
                        placeholder='Your Email' 
                        name='from_email' 
                    />
                    {formErrors.email && <p className="errorMsg">{formErrors.email}</p>}
                    
                    <label htmlFor="message" className="sr-only">Your Message</label>
                    <textarea 
                        id="message" 
                        name="message" 
                        placeholder='Your Message' 
                        rows={5} 
                        className={`msg ${formErrors.message ? 'error' : ''}`}
                    ></textarea>
                    {formErrors.message && <p className="errorMsg">{formErrors.message}</p>}
                    
                    <button type="submit" className='submitBtn'>Submit</button>
                </form>
                {isSubmitted && <p className="successMsg">Your message has been sent successfully!</p>}
            </div>
        </div>
    );
}

export default Contact;
