import React from 'react';
import './intro.css';
import bg from '../../assets/image.png';


const Intro = () => {
    return (
        <section id="intro">
            <div className="introContent">
                <span className="hello">Hello,</span>
                <span className="introText">I'm <span className="introName">Arshad</span> <br />I specialize in creating intelligent <br /> AI web apps using React/Next.JS. </span>
                <p className="introPara">My expertise includes integrating AI-driven features, ensuring seamless UX.</p>
            </div>
            <img src={bg} alt="Profile" className="bg" />
        </section>
    );
}

export default Intro;