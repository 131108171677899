import React from 'react';
import './skills.css'; // For additional custom styles if needed

const Skills = () => {
    return (
        <section id='skills' className="p-8 bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="container mx-auto">
                <span className="skillTitle text-center">About Me</span>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
                    {/* Personal Information Card */}
                    <div className="skillBox text-center">
                        <h2 className="text-xl font-semibold mb-4 text-gray-900">Personal Information</h2>
                        <ul className="list-disc pl-6 text-black">
                            Email: <a href="mailto:ars007.smv@gmail.com" className="text-blue-500 hover:underline">ars007.smv@gmail.com</a> <br />
                            Mobile: <a href="tel:+91-9731701337" className="text-blue-500 hover:underline">+91-9731701337</a> <br />
                            LinkedIn: <a href="https://www.linkedin.com/in/frontendevelopers/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">LinkedIn Profile</a> <br />
                            GitHub: <a href="https://github.com/arshad0220" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">GitHub Profile</a> <br />
                            Freelance Profile: <a href="https://pangea.app/profile/arshad-ahamed" className="text-blue-500 hover:underline">Freelance Profile</a> <br />
                        </ul>
                    </div>

                    {/* Professional Experience Card */}
                    <div className="skillBox text-center">
                        <h2 className="text-xl font-semibold mb-4 text-gray-900">Professional Experience</h2>
                        <ul className="list-disc pl-6 text-black">
                            <li>Over 2 years of experience as a React/Next.js developer.</li>
                            <li>Expertise in building performant and secure web applications.</li>
                            <li>Proficient in API integration and data management.</li>
                            <li>Experienced in creating collaborative user experiences.</li>
                        </ul>
                    </div>

                    {/* Educational Qualification Card */}
                    <div className="skillBox text-center">
                        <h2 className="text-xl font-semibold mb-4 text-gray-900">Educational Qualification</h2>
                        <ul className="list-disc pl-6 text-black">
                            <li>Bachelor of Engineering in Computer Science.</li>
                            <li>Graduated from SJB Institute of Technology, Bangalore, Karnataka.</li>
                            <li>Completed in 2022.</li>
                        </ul>
                    </div>

                    {/* Role at FreshOne Holdings LLC Card */}
                    <div className="skillBox text-center">
                        <h2 className="text-xl font-semibold mb-4 text-gray-900">Role at FreshOne Holdings LLC</h2>
                        <ul className="list-disc pl-6 text-black">
                            <li>Worked as a React.js Freelance Developer from November 2021 to December 2023.</li>
                            <li>Developed and maintained a temperature-controlled logistics application.</li>
                            <li>Achieved a 15% reduction in delivery reporting turnaround times.</li>
                            <li>Integrated one-click Stripe payment and utilized the Jest unit testing framework.</li>
                            <li>Designed and implemented RESTful APIs handling over 50,000 API requests per month.</li>
                            <li>Integrated the Samsara API for real-time temperature and location tracking.</li>
                        </ul>
                    </div>

                    {/* Role at Ycities Card */}
                    <div className="skillBox text-center">
                        <h2 className="text-xl font-semibold mb-4 text-gray-900">Role at Ycities</h2>
                        <ul className="list-disc pl-6 text-black">
                            <li>Worked as a Next.js Freelance Developer from February 2023 to February 2024.</li>
                            <li>Developed a custom, invite-only community web app using Next.js.</li>
                            <li>Focused on fostering youth entrepreneur networking.</li>
                            <li>Utilized TypeScript for type safety and maintainability.</li>
                            <li>Leveraged Prisma ORM for data persistence and retrieval.</li>
                            <li>Integrated Timely for goal progress tracking.</li>
                        </ul>
                    </div>

                    {/* Skills Card */}
                    <div className="skillBox text-center">
                        <h2 className="text-xl font-semibold mb-4 text-gray-900">Skills</h2>
                        <ul className="list-disc pl-6 text-black">
                            <li>JavaScript: Proficient in modern JavaScript (ES6+).</li>
                            <li>React.js: Experienced in building dynamic user interfaces.</li>
                            <li>Next.js: Skilled in server-side rendering and static site generation.</li>
                            <li>Tailwind CSS: Expert in utility-first CSS framework for custom designs.</li>
                            <li>Clerk for Authentication: Knowledgeable in user authentication and management.</li>
                            <li>Hasura Data Delivery Network: Experienced in real-time GraphQL APIs.</li>
                            <li>GraphQL APIs: Proficient in building and querying GraphQL APIs.</li>
                            <li>Vercel-Meticulous AI for Testing: Familiar with testing and deployment using Vercel.</li>
                            <li>Sanity.io for CMS: Experienced in content management systems.</li>
                            <li>Astro.build: Skilled in building fast, content-driven websites.</li>
                            <li>GitHub and GitLab: Proficient in version control and collaboration.</li>
                            <li>Vercel for CI/CD: Experienced in continuous integration and deployment.</li>
                            <li>Drizzle ORM: Knowledgeable in Object-Relational Mapping (ORM) with Drizzle.</li>
                            <li>UIVerse.io: Experienced with user interface design systems.</li>
                            <li>Lemon Squeezy: Familiar with SaaS subscription payment integration.</li>
                            <li>Astra DB: Skilled in AI databases and vector embedding.</li>
                            <li>Twilio: Knowledgeable in cloud communications and messaging.</li>
                            <li>Knock: Experienced in notification APIs.</li>
                            <li>Doppler: Skilled in secrets management and environment variables.</li>
                            <li>AWS S3 and SST-ION: Proficient in cloud storage and infrastructure.</li>
                            <li>Deploying Applications: Experienced with Vercel and Fly.io for deployment.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;
